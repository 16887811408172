<script setup>

</script>

<template>
    <section id="services" class="services-medium">
        <div class="services-medium__content-wrapper">
            <div class="services-medium__content">
                <ArticleBlockMedium class="services-medium__article-block" />
                <ServiceDescriptionBlockMedium class="services-medium__service-description-block" />
            </div>
        </div>
    </section>
</template>

<style lang="less">
.services-medium {
    height: 665px;
    background-color: #ffffff;

    &__content {
        width: 944px;
        height: 665px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__service-description-block {
        position: absolute;
        right: 0;
    }
}
</style>
